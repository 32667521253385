import React, {useEffect, useState} from 'react';
import './ModalCenter.scss';
import {GoPlus} from "react-icons/go";
import {ButtonAdd} from "../ButtonAdd/ButtonAdd";
import {VscGitPullRequestCreate} from "react-icons/vsc";
import {Form, Formik} from "formik";
import Modal from "react-modal";

export const ModalCenter = ({modalOpen, setModalOpen, children, contentLabel}) => {

    function closeModal() {
        setModalOpen(false);
    }

    const customStyles = {
        overlay: {
            background: 'rgb(0 0 0 / 35%)',
        },
        content: {
            top: '20vh',
            right: '30vh',
            height: 'fit-content',
            width: '40vw',
            left: '30vw',
        },
    };

    return (
        <div>
            <Modal

                isOpen={modalOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                closeTimeoutMS={200}
                ariaHideApp={true}
                style={customStyles}
            >
                <div className="crm__product-create-modal">
                    <div className="crm__product-create-modal-header">
                        <VscGitPullRequestCreate size="24px"/>
                        <h3>{contentLabel}</h3>
                    </div>
                    {children}
                </div>
            </Modal>
        </div>
    );
};