import React, {useEffect} from 'react';
import './CompanyBlock.scss';
import {Form, useFormikContext} from "formik";
import {Input} from "../field-components-new/Input";
import {Button} from "../Button/Button";
import Client from "../../api/Client";
import Company from "../../api/Company";
import NbuApi from "../../api/NbuApi";

export const CompanyForm = () => {
    const {
        values: { edrpou, iban },
        setFieldValue,
        errors,
        isSubmitting
    } = useFormikContext();

    const searchCompany = async() =>{
        if (edrpou) {
            if (edrpou.length >= 8) {
                const result = await Company.get({'edrpou': edrpou});
                let result_data = result.data.data[0];
                //console.log('result_data', result_data)
                if (result_data) {
                    await setFieldValue('name', result_data.name);
                    await setFieldValue('type', result_data.type);
                    await setFieldValue('address', result_data.address  ?? '');
                    await setFieldValue('iban', result_data.iban ?? '');
                    await setFieldValue('bank', result_data.bank  ?? '');
                    await setFieldValue('id', result_data.id  ?? null);
                } else{
                    await setFieldValue('name', '');
                    await setFieldValue('type', '');
                    await setFieldValue('address', '');
                    await setFieldValue('iban', '');
                    await setFieldValue('bank', '');
                    await setFieldValue('id', null);
                }
            }
        }
    }

    const BankInfo = async (mfo, setFieldValue) =>{
        await NbuApi.getBankInfo(mfo).then(function (response) {
            if(response.data.length > 0){
                let bank_name = response.data[0]?.SHORTNAME;
                setFieldValue('bank', bank_name);
            } else {
                setFieldValue('bank', '');
            }
        });
    }

    useEffect( () => {
        if (iban && !errors?.iban?.lenght && iban !== "UA") {
            let mfo = iban.substring(4, 10);
            BankInfo(mfo, setFieldValue);
        }
    }, [iban])

    useEffect(  () => {
        searchCompany()
    }, [edrpou])

    return (
        <Form>
            <div className="tf-form__row">
                <div className="tf-form__col w25">
                    <Input type="text" name="edrpou" label="ЄДРПОУ"
                           mask="9999999999"></Input>
                </div>
                <div className="tf-form__col w25">
                    <Input type="text" name="type"
                           label="Тип"></Input>
                </div>
                <div className="tf-form__col w50">
                    <Input type="text" name="name"
                           label="Назва компанії"></Input>
                </div>
            </div>
            <div className="tf-form__row">
                <div className="tf-form__col w100">
                    <Input type="text" name="address"
                           label="Адреса"></Input>
                </div>
            </div>
            <div className="tf-form__row">
                <div className="tf-form__col w60">
                    <Input type="text" name="iban" label="Рахунок (IBAN)"
                           mask="UA999999999999999999999999999"></Input>
                </div>
                <div className="tf-form__col w40">
                    <Input type="text" name="bank" label="Банк" disabled></Input>
                </div>
            </div>
            <div className="tf-form__row justify-content-center">
                <Button
                    submit
                    className={isSubmitting ? "disabled-btn" : ""}
                    disabled={isSubmitting}
                >
                    Продовжить
                </Button>
            </div>
        </Form>
    )
};