import React, {useEffect, useState} from 'react';
import './CompanyBlock.scss';
import {Formik, useFormikContext} from "formik";
import {SimpleBlock} from "../SimpleBlock";
import * as Yup from "yup";
import {CompanyForm} from "./CompanyForm";
import Company from "../../api/Company";

export const CompanyBlock = ({children, contentLabel, ...props}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [text, setText] = useState('Компанія не обрана');

    const {
        values: { company },
        setFieldValue,
    } = useFormikContext();

    useEffect(  () => {
        if(company && company.name && company.edrpou){
            setText(company.name + " ( ЄДРПОУ: " + company.edrpou + " )");
        }
    }, [company])

    const defaultValues = {
        edrpou: "",
        name: "",
        address: "",
        type: "",
        iban: "",
        bank: "",
    };


    const initialValues = {
        ...defaultValues,
        ...company
    }

    const SignInSchema = Yup.object().shape({
        edrpou: Yup.string().min(8).max(10).required(),
        name: Yup.string().required(),
        address: Yup.string(),
        type: Yup.string().required(),
        iban: Yup.string(),
        bank: Yup.string(),
    });

    const onSubmit = async (values) => {
        if(values){
            const result = await Company.create(values);
            if(result.success){
                setFieldValue('company', values)
                setIsOpen(false)
            }
        }
    };

    return (
        <SimpleBlock text={text} contentLabel="Компанія" modal={{modalIsOpen, setIsOpen}} {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={SignInSchema}
                onSubmit={onSubmit}
            >
                <CompanyForm/>
            </Formik>
        </SimpleBlock>
    );
};