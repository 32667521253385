import React, {useState} from 'react';
import './SimpleBlock.scss';
import {ButtonAdd} from "../ButtonAdd/ButtonAdd";
import {GoPlus} from "react-icons/go";
import {ModalCenter} from "../ModalCenter/ModalCenter";
import cx from "classnames";

export const SimpleBlock = ({children, text, contentLabel, modal, ...props}) => {
    const {modalIsOpen, setIsOpen} = modal;
    const { className} = props;
    console.log('className', className)
    const blockClasses = cx(
        'simple-block',
        className,
    );

    return (
        <div className={blockClasses}>
            <div className="wrapper">
                <div className="header">
                    <span>{contentLabel}</span>
                    <ButtonAdd onClick={() => setIsOpen(true)}>
                        <GoPlus size="18px"/>
                    </ButtonAdd>
                </div>
                <div className="content">
                    {text}
                </div>
            </div>
            <ModalCenter
                modalOpen={modalIsOpen}
                setModalOpen={setIsOpen}
                contentLabel={contentLabel}
            >
                {children}
            </ModalCenter>
        </div>
    );
};