import React, {useState} from 'react';
import './ProductCart.scss';
import {GoPlus} from "react-icons/go";
import {ButtonAdd} from "../ButtonAdd/ButtonAdd";
import {FieldArray, Form, Formik} from "formik";
import {ModalCenter} from "../ModalCenter/ModalCenter";
import {Input} from "../field-components-new/Input";
import Product from "../../api/Product";
import Popup from "reactjs-popup";
import {SimpleModal} from "../SimpleModal";
import Modal from "react-modal";

export const ProductCart = ({arrayHelpers}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [productList, setProductList] = useState(false);
    const onSubmit = async (values) => {
        const result = await Product.getProducts(values).then(function (response) {
            setProductList(response.data.data);
        });
    }

    const initialValues = {
        search: '',
    };

    return (
        <div className="crm__product-cart">
            <div className="crm__product-cart-header">
                <span>Товар</span>
                <ButtonAdd onClick={() => setIsOpen(true)}>
                    <GoPlus size="18px"/>
                </ButtonAdd>
            </div>

            <ModalCenter
                modalOpen={modalIsOpen}
                setModalOpen={setIsOpen}
                contentLabel="Вибір товару"
            >
                <div className="crm__select-product">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                    >
                        {({isSubmitting, isValid, dirty}) => (
                            <Form>
                                <div className="filter">
                                    <Input type="text" name="search" placeholder="Назва або код товару"
                                           autoSubmit={true}></Input>
                                    {/*<SearchDropdownField name="category" placeholder="Категорія" autoSubmit={true}/>*/}
                                </div>
                            </Form>
                        )}
                    </Formik>

                    <div className="items">
                        {productList && productList.map((item, key) => {
                            const clickItem =  () => {
                                // let array_temp = arrayHelpers.find(arrayItem => arrayItem.id === item.id)
                                // if(array_temp){
                                //     array_temp.count = array_temp.count + 1;
                                // } else {
                                //     arrayHelpers.insert(0, {'name': item.name, 'id': item.id, 'price': item.price, 'count': 1})
                                // }
                                console.log(arrayHelpers)
                                arrayHelpers.insert(0, {'name': item.name, 'id': item.id, 'price': item.price, 'count': 1})
                                setIsOpen(false)
                            }
                            return (
                                <div className="item-wrapper">
                                    <div className="item" onClick={clickItem}>
                                        <div className="container-img"></div>
                                        <h4 className="name">{item.name} ({item.id})</h4>
                                        <span className="price">Ціна: {item.price} грн.</span>
                                        <span className="price">Наявність: {key} шт.</span>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div>


            </ModalCenter>
        </div>
    );
};