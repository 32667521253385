import React, {useState} from 'react';
import './DeliveryBlock.scss';
import {Form, Formik} from "formik";
import {Input} from "../field-components-new/Input";
import {SimpleBlock} from "../SimpleBlock";
import * as Yup from "yup";

export const DeliveryBlock = ({children, text, contentLabel, ...props}) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const initialValues = {
        edrpou: "",
        name_company: "",
        iban_company: "",
        address_company: "",
        type_company: "ФОП",
        comment: 'test',
        products: [],
    };

    const SignInSchema = Yup.object().shape({
        comment: Yup.string(),
        products: Yup.array(),
    });

    const onSubmit = async (values) => {
        setIsOpen(false)
        // const result = await Order.create(values);
        // if (result.success) {
        //     console.log(result)
        //     setIsOpen(false)
        // }
    };

    return (
        <SimpleBlock text="Доставка не обрана" contentLabel="Доставка" modal={{modalIsOpen, setIsOpen}} {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={SignInSchema}
                onSubmit={onSubmit}
            >
                {(formik) => {
                    const {
                        isSubmitting
                    } = formik;
                    return (
                        <Form>
                            <div className="tf-form__row">
                                <div className="tf-form__col w33">
                                    <Input type="text" name="type" label="Тип доставки"
                                           mask="99999999"></Input>
                                </div>
                            </div>
                            <div className="tf-form__row">
                                <button
                                    type="submit"
                                    className={isSubmitting ? "disabled-btn" : ""}
                                    disabled={isSubmitting}
                                >
                                    Продовжить
                                </button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </SimpleBlock>
    );
};