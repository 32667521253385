import React, {useEffect} from 'react'
import {Link, useNavigate} from "react-router-dom"
import {Field, FieldArray, Form, Formik, useFormikContext} from "formik";
import "./OrderCreate.scss";
import * as Yup from 'yup';
import {Input} from "../../components/field-components-new/Input";
import NbuApi from "../../api/NbuApi";
import Order from "../../api/Order";
import {SearchDropdownField} from "../../components/field-components-new/SearchDropdownField";
import {TextArea} from "../../components/field-components-new/TextArea";
import {HeaderNav} from "../../components/HeaderNav";
import {ProductCart} from "../../components/ProductCart/ProductCart";
import {FormGroup} from "../../components/field-components-new/FormGroup";
import {SimpleBlock} from "../../components/SimpleBlock";
import {CompanyBlock} from "../../components/CompanyBlock";
import {DeliveryBlock} from "../../components/DeliveryBlock";
import {Button} from "../../components/Button/Button";



function OrderCreate() {
    const navigate = useNavigate();

    const initialValues = {
        phone: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        edrpou: "",
        name_company: "",
        iban_company: "",
        address_company: "",
        type_company: "ФОП",
        comment: 'test',
        products: [],
        company: {},
    };

    const SignInSchema = Yup.object().shape({
        phone: Yup.string().required().min(12),
        first_name: Yup.string().required().min(3),
        last_name: Yup.string().required().min(3),
        middle_name: Yup.string().nullable(),
        comment: Yup.string(),
        // products: Yup.array(),
        // company: Yup.array(),
    });

    const onChange = (value) => {
        console.log(value);
    };


    const onSubmit = async (values) => {
        const result = await Order.create(values);
        if (result.success) {
            console.log(result)
            navigate("/orders");
        }
    };

    const ClientFields = (props) => {
        const {
            values: { search },
            setFieldValue,
            errors,
        } = useFormikContext();
        // const [field, meta] = useField(props);

        useEffect( () => {
            if (search) {
                setFieldValue('phone', search.phone);
                setFieldValue('last_name', search.last_name);
                setFieldValue('first_name', search.first_name);
                setFieldValue('middle_name', search.middle_name);
            }
        }, [search])

        return (
            <div>
                <div className="tf-form__row">
                    <div className="tf-form__col w50">
                        <Input type="text" name="phone" label="Номер телефону"
                               mask="+38 (099) 999 99 99"></Input>
                    </div>
                    <div className="tf-form__col w50">
                        <Input type="text" name="first_name" label="Прізвище"></Input>
                    </div>
                </div>
                <div className="tf-form__row">
                    <div className="tf-form__col w50">
                        <Input type="text" name="last_name" label="Імя"></Input>
                    </div>
                    <div className="tf-form__col w50">
                        <Input type="text" name="middle_name"
                               label="По батькові"></Input>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <HeaderNav title="Створення замовлення"></HeaderNav>


            <DeliveryBlock className="w-25"></DeliveryBlock>

            <Formik
                initialValues={initialValues}
                validationSchema={SignInSchema}
                onSubmit={onSubmit}
            >
                {(formik) => {

                    const {
                        errors,
                        touched,
                        isSubmitting, isValid,
                        dirty, values,
                        setFieldValue,
                        value,
                    } = formik;

                    return (
                        <div>
                            <Form>
                                <CompanyBlock className="w-25"></CompanyBlock>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <FieldArray
                                            name="products"
                                            render={arrayHelpers => (
                                                <div className="products-block">
                                                    <ProductCart arrayHelpers={arrayHelpers}></ProductCart>

                                                    <div className="products">
                                                        <div className="products-wrapper">
                                                            {values.products && values.products.length > 0 ? (
                                                                values.products.map((product, index) => (
                                                                    <div className="product" key={index}>
                                                                        <div className="product-wrapper">
                                                                            <span className="name">{product.name}</span>
                                                                            <div className="data">
                                                                                <Input
                                                                                    name={`products.${index}.price`}/>
                                                                                <Input
                                                                                    name={`products.${index}.count`}/>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                                                >
                                                                                    -
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <>
                                                                    Список пустий
                                                                </>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="tf-form__row">
                                            <SearchDropdownField name="search" placeholder="Номер телефону або ПІБ клієнта"/>
                                        </div>
                                        <ClientFields></ClientFields>

                                        <div className="tf-form__row d-none">
                                            <div className="tf-form__col w33">
                                                <Input type="text" name="edrpou" label="ЄДРПОУ"
                                                       mask="99999999"></Input>
                                            </div>
                                            <div className="tf-form__col w33">
                                                <Input type="text" name="type_company"
                                                       label="Тип"></Input>
                                            </div>
                                            <div className="tf-form__col w33">
                                                <Input type="text" name="address_company"
                                                       label="Адреса"></Input>
                                            </div>
                                            <div className="tf-form__col w33">
                                                <Input type="text" name="name_company"
                                                       label="Назва компанії"></Input>
                                            </div>
                                        </div>

                                        {/*<BankFields/>*/}

                                        <div className="tf-form__col w33">
                                            <TextArea name="comment"></TextArea>
                                        </div>
                                    </div>
                                </div>

                                <Button
                                    submit
                                    className={isSubmitting ? "disabled-btn" : ""}
                                    disabled={isSubmitting}
                                >
                                    Продовжить
                                </Button>
                            </Form>
                        </div>
                    );
                }}
            </Formik>

        </div>
    )
        ;
}

export default OrderCreate;