import axios from "axios";
import {SignupRequestError} from "./SignupRequestError";
const endpoint = 'https://api.trueflex.com.ua/api/client';

const processResponse = (response) => {
    const { data } = response;
    if (!data) {
        throw new Error('Unknown response from server');
    } else if (data.success !== true) {
        throw new SignupRequestError(data.error);
    }
    return data;
};
export default class Client {

    static async get(data) {
        return await axios.get(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        });
    }
    static async create(payload) {
        let response = processResponse(await axios({
            method: 'POST',
            url: endpoint + '/create',
            data: { ...payload },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }))

        console.log("response",response)
        return response;
    }
}